import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Layout, Menu, notification } from "antd";
import { Link } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { findActiveMenu } from "@utils/menu";
import mockMenu from "@api/mock/menu";

let SubMenu = Menu.SubMenu;

let { Sider } = Layout;

let defaultProps = {
    items: [],
};

let propTypes = {
    items: PropTypes.array,
};

function Sidebar(props) {

    const { history } = props;
    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') == 1);
    const [openKeys, setOpenKeys] = useState([]);
    const [activeKeys, setActiveKeys] = useState("");
    const [menu, setMenu] = useState([]);

    const user = props.user

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
    }, []);

    const renderMenu = (user) => {
        let _menuProcess = (items) => {
            return (
                Array.isArray(items) &&
                items.map((item) => {
                    if (item.children && item.children.length > 0) {
                        let menu = _menuProcess(item.children);
                        return user.hasRole(item.roles) ? (
                            <SubMenu
                                key={"sub" + item.key}
                                title={
                                    <span>
                                        {item.icon}
                                        <span className="nav-text">{item.name}</span>
                                    </span>
                                }
                            >
                                {menu}
                            </SubMenu>
                        ) : null
                    } else {
                        return user.hasRole(item.roles) ? (item.url ? (
                            <Menu.Item key={"menu" + item.key}>
                                <Link to={item.url}>
                                    {item.icon}
                                    <span>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        ) : (
                            <Menu.Item key={"menu" + item.key}>
                                {item.icon}
                                <span>{item.name}</span>
                            </Menu.Item>
                        )) : null
                    }
                })
            );
        };

        setMenu(_menuProcess(mockMenu));
    };

    useEffect(() => {
        if (user) {
            activeMenu(location.pathname)
            history.listen(location => {
                activeMenu(location.pathname)
            });
            renderMenu(user);
        }
    }, [user])

    const toggle = () => {
        localStorage.setItem('collapsed', collapsed ? 0 : 1)
        setCollapsed(!collapsed);
    };

    const activeMenu = function (pathname) {
        let data = findActiveMenu(pathname);
        if (data) {
            data.active ? setActiveKeys([`menu${data.active}`]) : setActiveKeys([])
            data.open ? setOpenKeys([`sub${data.open}`]) : setOpenKeys([])
        }
    }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} className="noPrint">
            <div className="logo">
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: "trigger",
                    onClick: toggle,
                })}
                <div className="ant-layout-logo">{!collapsed ? "VOD" : ""}</div>
            </div>
            <Menu
                selectedKeys={activeKeys}
                openKeys={openKeys}
                theme="dark"
                mode="inline"
                siderCollapsed={collapsed}
                onOpenChange={keys => {
                    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
                    setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
                }}
            >
                {menu}
            </Menu>
        </Sider>
    );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default withRouter(Sidebar);
