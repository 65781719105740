import Home from '../containers/Home'
import User from '../containers/User'
import UserDetail from '../containers/User/Detail'
import Profile from '../containers/Profile'
import Playlist from '../containers/Playlist'
import PlaylistDetail from '../containers/Playlist/Detail'
import Video from '../containers/Video'
import VideoDetail from '../containers/Video/Detail'
import VideoUpload from '../containers/Video/Upload'

export const mainRoutes = [
    {
        path: "/",
        component: Home,
        exactly: true,
    },
    {
        path: "/profile",
        component: Profile,
        exactly: true,
    },
    {
        path: "/user",
        component: User,
        exactly: true,
        roles: 'ADMIN',
    },
    {
        path: "/user/new",
        component: UserDetail,
        exactly: true,
        roles: 'ADMIN',
    },
    {
        path: "/user/:id",
        component: UserDetail,
        exactly: true,
        roles: 'ADMIN',
    },
    {
        path: "/playlist",
        component: Playlist,
        exactly: true,
    },
    {
        path: "/playlist/new",
        component: PlaylistDetail,
        exactly: true,
    },
    {
        path: "/playlist/:id",
        component: PlaylistDetail,
        exactly: true,
    },
    {
        path: "/video",
        component: Video,
        exactly: true,
    },
    {
        path: "/video/upload",
        component: VideoUpload,
        exactly: true,
    },
    {
        path: "/video/:id",
        component: VideoDetail,
        exactly: true,
    },
];
