import { Button, Col, Input, message, Row, Tabs } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import User from "../../utils/user";
import CenterArea from "../../components/CenterArea"
import NewForm, { FieldFloatItem } from "../../components/NewForm"
import api from "@api"

const Profile = () => {

    const roles = useSelector(state => state.auth.roles)
    const data = useSelector(state => state.auth.userData)
    const user = new User(roles, data)
    const [form] = NewForm.useForm();
    const [form2] = NewForm.useForm();

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            if (values.name.trim().length === 0) {
                message.error("Vui lòng nhập họ tên!");
            } else {
                let data = {
                    name: values.name
                }
                api.put('/user/profile', data)
                    .then(({ status }) => {
                        if (status) {
                            message.success("Cập nhật thành công!");
                        } else {
                            message.error("Cập nhật không thành công!");
                        }
                    })
            }
        });
    }

    const onChangePassword = () => {
        form2.validateFields().then(async (values) => {
            if (values.password?.trim().length === 0 || !values.password) {
                message.error("Vui lòng nhập mật khẩu hiện tại!");
            } else if (values.new_password?.trim().length === 0 || !values.new_password) {
                message.error("Vui lòng nhập mật khẩu mới!");
            }
            let data = {
                password: values.password,
                new_password: values.new_password,
            }
            api.put('/user/changepass', data)
                .then(data => {
                    if (data.status) {
                        message.success("Cập nhật thành công!");
                    } else {
                        message.error(data.message || "Cập nhật không thành công!");
                    }
                })
        });
    }

    useEffect(() => {
        form.setFieldsValue({
            name: user.getName(),
            username: user.getUsername(),
        })
    }, [])

    return (
        <Tabs defaultActiveKey={1}>
            <Tabs.TabPane tab="Thông tin" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm id="course-editor-form" className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Username"
                                    name="username"
                                >
                                    <Input readOnly disabled />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Name"
                                    name="name"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <FieldFloatItem>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                            >Cập nhật</Button>
                                        </Col>
                                    </Row>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Đổi mật khẩu" key={2}>
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form2} onFinish={onChangePassword}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Mật khẩu hiện tại"
                                    name="password"
                                >
                                    <Input type="password" autoComplete="new-password" />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Mật khẩu mới"
                                    name="new_password"
                                >
                                    <Input type="password" autoComplete="new-password" />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <FieldFloatItem>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                            >Cập nhật</Button>
                                        </Col>
                                    </Row>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </Tabs.TabPane>
        </Tabs>
    )
}

export default Profile