import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, Button, Row, Col, message, Checkbox, Modal, notification } from "antd";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { withRouter } from "react-router-dom";
import { login_pending, login_success, login_error } from "@actions/auth";
// import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getBrowser, MD5 } from "@utils";
import api from "@api";
import "./index.scss";

let propTypes = {
    user: PropTypes.object,
    loggingIn: PropTypes.bool,
    loginErrors: PropTypes.string,
};

const Login = (props) => {
    // const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visibleResetPass, setVisibleResetPass] = useState(false);
    const [email_reset, setEmailReset] = useState(null);
    const [form] = NewForm.useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        form.validateFields().then((values) => {
            let data = values;
            if (data.remember) {
                localStorage.setItem("rem", data.remember);
                localStorage.setItem("username", data.email);
            }

            dispatch(login_pending());
            api.post("/user/login", {
                email: data.email,
                password: data.password,
                device_id: MD5(data.email + getBrowser() + Math.floor(Math.random() * 100000000)),
            })
                .then((res) => {
                    setLoading(false);
                    if (!res.status) {
                        message.error("Đăng nhập không thành công");
                        dispatch(login_error("Đăng nhập không thành công"));
                    } else {
                        dispatch(login_success(res));
                        message.success("Welcome " + res.user.username);
                        props.history.replace("/");
                        // if (res.user.is_admin) {
                        //     props.history.replace("/");
                        // } else {
                        //     props.history.replace("/student");
                        // }
                    }
                })
                .catch(() => {
                    message.error("Đăng nhập không thành công");
                    setLoading(false);
                });
        });
    };

    const handleResetPass = () => {
        if (email_reset) {
            setConfirmLoading(true);
            api.put("/user/resetpass", {
                email: email_reset,
            }).then(
                (res) => {
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Kiểm tra email để nhận mật khẩu mới!",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message,
                        });
                    }
                    setConfirmLoading(false);
                    setVisibleResetPass(false);
                },
                () => {
                    setConfirmLoading(false);
                }
            );
        } else {
            notification.error({
                message: "Cảnh báo",
                description: "Vui lòng nhập email",
            });
        }
    };

    return (
        <>
            <div className="bg-login">
                <Row className="login-form" justify="space-around" align="middle">
                    <Col className="col-width">
                        <NewForm
                            className="new-form"
                            form={form}
                            onFinish={handleSubmit}
                            initialValues={{
                                remember: localStorage.getItem("rem") ? localStorage.getItem("rem") === "true" : false,
                            }}
                        >
                            <h2 className="logo" style={{ textAlign: "center" }}>
                                <span>Phuong Nam VOD</span>
                            </h2>
                            <FieldFloatItem
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập địa chỉ email",
                                    },
                                ]}
                            >
                                <Input />
                            </FieldFloatItem>
                            <FieldFloatItem
                                label="Mật khẩu"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập mật khẩu",
                                    },
                                ]}
                            >
                                <Input type="password" />
                            </FieldFloatItem>
                            <FieldFloatItem name="remember" valuePropName="checked">
                                <Row justify="space-between">
                                    <Checkbox>Ghi nhớ</Checkbox>
                                    <a
                                        className="login-form-forgot"
                                        // onClick={() => setVisibleResetPass(true)}
                                    >
                                        Quên mật khẩu
                                    </a>
                                </Row>
                            </FieldFloatItem>
                            <Row>
                                <Col>
                                    <Button
                                        className="btn-login"
                                        type="primary"
                                        size="large"
                                        loading={loading}
                                        htmlType="submit"
                                    >
                                        Đăng nhập
                                    </Button>
                                </Col>
                            </Row>
                        </NewForm>
                        <Modal
                            title="Reset Password"
                            visible={visibleResetPass}
                            onOk={handleResetPass}
                            confirmLoading={confirmLoading}
                            onCancel={() => setVisibleResetPass(false)}
                        >
                            <Input
                                placeholder="Email đã đăng ký"
                                value={email_reset}
                                onChange={(e) => setEmailReset(e.target.value)}
                            />
                        </Modal>
                    </Col>
                </Row>
            </div>
        </>
    );
};

Login.propTypes = propTypes;

export default withRouter(Login);
