import { combineReducers } from "redux";
import auth from "./auth";
import modal from "./modal";
import acl from "./acl";
import branch from "./branch";

export default combineReducers({
    auth,
    modal,
    acl,
    branch,
});
