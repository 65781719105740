import api from "@api";
import { Modal } from "antd";
import { useMemo } from "react";
import { useState } from "react";

const { confirm } = Modal;

var timeout;
var dataFormCache = {};

export const heightDesktopConfig = 200;

export const heightTabletConfig = 310;

export const toArray = (rawData) => {
    let arr = [];
    const objectArray = Object.entries(rawData);
    objectArray.forEach(([key, value]) => {
        arr.push({ id: parseFloat(key), name: value });
    });
    return arr;
};

export const getLocalColumns = (tableName, fixColumns) => {
    let localColumns;
    let result = [];
    try {
        localColumns = JSON.parse(localStorage.getItem(tableName));
        localColumns?.length &&
            localColumns.map((item) => {
                if (item.status == true) {
                    result.push(item.key);
                }
            });
    } catch (err) {
        console.log("err", err);
    }
    if (!(localColumns instanceof Array)) {
        result = fixColumns.map((i) => i.key);
    }
    return result;
};

export const getHeightOfMainContent = () => {
    let elHeight = 300;
    const el = document.getElementById("main-content");
    if (el) {
        elHeight = el.offsetHeight - heightDesktopConfig;
    }
    return parseInt(elHeight);
};

export const toDateFormat = () => {
    var todayDate = new Date();
    todayDate.setMinutes(todayDate.getMinutes() - todayDate.getTimezoneOffset());
    return todayDate.toISOString().slice(0, 10);
};

export const useStateCustom = (defState) => {
    const [state, setState] = useState(defState);

    const setNewState = (newState) => {
        if (newState !== state) {
            setState(newState);
        }
    };
    return [state, setNewState];
};

export const useComponentWillMount = (func) => {
    useMemo(func, []);
};

export const getFilterString = (filterData = {}) => {
    let str = "";
    for (let key in filterData) {
        str += key + ":" + filterData[key] + ";";
    }
    return str;
};

export const handleCacheData = (data) => {
    dataFormCache = { ...dataFormCache, ...data };
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        setFormToLocalStorage(dataFormCache);
    }, 500);
};

export const removeDataFormCache = () => {
    dataFormCache = {};
};

export const setFormToLocalStorage = (data) => {
    const { href } = window.location;
    let formName = "";
    const array = [
        { ref: "student-course", name: "formCacheCourseDetail" },
        { ref: "collaborator-lead", name: "formCacheAddCollaboratorLead" },
        { ref: "lead", name: "formCacheLead" },
        { ref: "student", name: "formCacheStudent" },
        { ref: "teacher", name: "formCacheTeacher" },
        { ref: "lesson", name: "formCacheLesson" },
        { ref: "course", name: "formCacheCourse" },
        { ref: "classroom", name: "formCacheClassroom" },
        { ref: "invoice", name: "formCacheInvoice" },
        { ref: "collaborator", name: "formCacheAddCollaborator" },
    ];
    for (let i = 0; i < array.length - 1; i++) {
        if (href.includes(array[i].ref)) {
            formName = array[i].name;
            break;
        }
    }

    let form = JSON.parse(localStorage.getItem(formName));
    form = { ...form, ...data };
    localStorage.setItem(formName, JSON.stringify(form));
};

export const confirmRestorseLocalStorage = (hanldeOk, handleCancel) => {
    confirm({
        title: "Gợi ý",
        content: "Gần đây bạn đã nhập form này, Bạn có muốn khôi phục form cũ đã nhập không?",
        onOk() {
            hanldeOk();
        },
        onCancel() {
            handleCancel();
        },
    });
};

export function validateObject(obj) {
    for (var i in obj) {
        if (obj[i] === undefined) {
            obj[i] = null;
        }
    }
    return obj;
}

export function formatMoney(n, c, d, t) {
    (c = isNaN((c = Math.abs(c))) ? 0 : c),
        (d = d == undefined ? "" : d),
        (t = t == undefined ? "." : t),
        (s = n < 0 ? "-" : ""),
        (i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))))),
        (j = (j = i.length) > 3 ? j % 3 : 0);

    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
}

export function changeStateOnline() {
    var nav = document.getElementsByClassName("am-navbar");
    if (nav[0]) {
        if (window.navigator.onLine) {
            nav[0].style.backgroundColor = "#bf0404";
        } else {
            nav[0].style.backgroundColor = "#949494";
        }
    }
}

export function getBrowser() {
    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || safari.pushNotification);

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    var isChrome = !!window.chrome && !!window.chrome.webstore;

    return isOpera
        ? "Opera"
        : isFirefox
        ? "Firefox"
        : isSafari
        ? "Safari"
        : isChrome
        ? "Chrome"
        : isIE
        ? "IE"
        : isEdge
        ? "Edge"
        : "Don't know";
}

export function createConstants(...constants) {
    return constants.reduce((acc, constant) => {
        acc[constant] = constant;
        return acc;
    }, {});
}

export function isNumber(input) {
    input = parseFloat(input);
    return !Number.isNaN(input) && Number.isFinite(input);
}

export function generateUuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function generateDateTime(date = new Date()) {
    return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ].join("");
}

export function generateDate(date = new Date()) {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join("");
}

export function mergeObjects(obj1, obj2) {
    for (let key in obj2) {
        obj1[key] = obj2[key];
    }
    return obj1;
}

export function isPromise(value) {
    if (value !== null && typeof value === "object") {
        return value.promise && typeof value.promise.then === "function";
    }
}

export function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

export function pushNotification(type, msg, course_id, role, meta, user_ids = []) {
    api.post("/fcm/notification", {
        type: type,
        message: msg,
        course_id: course_id,
        role: role,
        meta: meta,
        user_ids: user_ids,
    });
}

export function getCurrentVersion() {
    return new Promise((resolve, reject) => {
        fetch("/index.html", { cache: "no-cache" })
            .then((res) => {
                return res.text();
            })
            .then((body) => {
                const regex = /main.(\w+).chunk.js/gm;
                const match = body.match(regex);
                if (match) {
                    const new_hash = match[0].split(".")[1];
                    resolve(new_hash);
                }
            })
            .catch(() => {
                reject(null);
            });
    });
}

export function showNewVersionPopup() {
    confirm({
        title: "Có phiên bản mới cập nhật?",
        content: "Nhấn vào nút OK để cập nhật, nhấn hủy để bỏ qua!",
        onOk() {
            location.reload();
        },
    });
}

export function numberWithCommas(x) {
    if (x) {
        x = x.toString().replace(/\D/g, "").replace(/\./g, "");
        return parseInt(x)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
        return "0";
    }
}

export function numberWithoutCommas(x) {
    if (x) {
        return x.toString().replace(/\./g, "");
    } else {
        return 0;
    }
}

export function copyToClipboard(str) {
    const el = document.createElement("textarea"); // Create a <textarea> element
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
    el.style.position = "absolute";
    el.style.left = "-9999px"; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }
}

export function MD5(d) {
    let result = M(V(Y(X(d), 8 * d.length)));
    return result.toLowerCase();
}
function M(d) {
    for (var _, m = "0123456789ABCDEF", f = "", r = 0; r < d.length; r++)
        (_ = d.charCodeAt(r)), (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _));
    return f;
}
function X(d) {
    for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0;
    for (m = 0; m < 8 * d.length; m += 8) _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32;
    return _;
}
function V(d) {
    for (var _ = "", m = 0; m < 32 * d.length; m += 8) _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255);
    return _;
}
function Y(d, _) {
    (d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _);
    for (var m = 1732584193, f = -271733879, r = -1732584194, i = 271733878, n = 0; n < d.length; n += 16) {
        var h = m,
            t = f,
            g = r,
            e = i;
        (f = md5_ii(
            (f = md5_ii(
                (f = md5_ii(
                    (f = md5_ii(
                        (f = md5_hh(
                            (f = md5_hh(
                                (f = md5_hh(
                                    (f = md5_hh(
                                        (f = md5_gg(
                                            (f = md5_gg(
                                                (f = md5_gg(
                                                    (f = md5_gg(
                                                        (f = md5_ff(
                                                            (f = md5_ff(
                                                                (f = md5_ff(
                                                                    (f = md5_ff(
                                                                        f,
                                                                        (r = md5_ff(
                                                                            r,
                                                                            (i = md5_ff(
                                                                                i,
                                                                                (m = md5_ff(
                                                                                    m,
                                                                                    f,
                                                                                    r,
                                                                                    i,
                                                                                    d[n + 0],
                                                                                    7,
                                                                                    -680876936
                                                                                )),
                                                                                f,
                                                                                r,
                                                                                d[n + 1],
                                                                                12,
                                                                                -389564586
                                                                            )),
                                                                            m,
                                                                            f,
                                                                            d[n + 2],
                                                                            17,
                                                                            606105819
                                                                        )),
                                                                        i,
                                                                        m,
                                                                        d[n + 3],
                                                                        22,
                                                                        -1044525330
                                                                    )),
                                                                    (r = md5_ff(
                                                                        r,
                                                                        (i = md5_ff(
                                                                            i,
                                                                            (m = md5_ff(
                                                                                m,
                                                                                f,
                                                                                r,
                                                                                i,
                                                                                d[n + 4],
                                                                                7,
                                                                                -176418897
                                                                            )),
                                                                            f,
                                                                            r,
                                                                            d[n + 5],
                                                                            12,
                                                                            1200080426
                                                                        )),
                                                                        m,
                                                                        f,
                                                                        d[n + 6],
                                                                        17,
                                                                        -1473231341
                                                                    )),
                                                                    i,
                                                                    m,
                                                                    d[n + 7],
                                                                    22,
                                                                    -45705983
                                                                )),
                                                                (r = md5_ff(
                                                                    r,
                                                                    (i = md5_ff(
                                                                        i,
                                                                        (m = md5_ff(
                                                                            m,
                                                                            f,
                                                                            r,
                                                                            i,
                                                                            d[n + 8],
                                                                            7,
                                                                            1770035416
                                                                        )),
                                                                        f,
                                                                        r,
                                                                        d[n + 9],
                                                                        12,
                                                                        -1958414417
                                                                    )),
                                                                    m,
                                                                    f,
                                                                    d[n + 10],
                                                                    17,
                                                                    -42063
                                                                )),
                                                                i,
                                                                m,
                                                                d[n + 11],
                                                                22,
                                                                -1990404162
                                                            )),
                                                            (r = md5_ff(
                                                                r,
                                                                (i = md5_ff(
                                                                    i,
                                                                    (m = md5_ff(m, f, r, i, d[n + 12], 7, 1804603682)),
                                                                    f,
                                                                    r,
                                                                    d[n + 13],
                                                                    12,
                                                                    -40341101
                                                                )),
                                                                m,
                                                                f,
                                                                d[n + 14],
                                                                17,
                                                                -1502002290
                                                            )),
                                                            i,
                                                            m,
                                                            d[n + 15],
                                                            22,
                                                            1236535329
                                                        )),
                                                        (r = md5_gg(
                                                            r,
                                                            (i = md5_gg(
                                                                i,
                                                                (m = md5_gg(m, f, r, i, d[n + 1], 5, -165796510)),
                                                                f,
                                                                r,
                                                                d[n + 6],
                                                                9,
                                                                -1069501632
                                                            )),
                                                            m,
                                                            f,
                                                            d[n + 11],
                                                            14,
                                                            643717713
                                                        )),
                                                        i,
                                                        m,
                                                        d[n + 0],
                                                        20,
                                                        -373897302
                                                    )),
                                                    (r = md5_gg(
                                                        r,
                                                        (i = md5_gg(
                                                            i,
                                                            (m = md5_gg(m, f, r, i, d[n + 5], 5, -701558691)),
                                                            f,
                                                            r,
                                                            d[n + 10],
                                                            9,
                                                            38016083
                                                        )),
                                                        m,
                                                        f,
                                                        d[n + 15],
                                                        14,
                                                        -660478335
                                                    )),
                                                    i,
                                                    m,
                                                    d[n + 4],
                                                    20,
                                                    -405537848
                                                )),
                                                (r = md5_gg(
                                                    r,
                                                    (i = md5_gg(
                                                        i,
                                                        (m = md5_gg(m, f, r, i, d[n + 9], 5, 568446438)),
                                                        f,
                                                        r,
                                                        d[n + 14],
                                                        9,
                                                        -1019803690
                                                    )),
                                                    m,
                                                    f,
                                                    d[n + 3],
                                                    14,
                                                    -187363961
                                                )),
                                                i,
                                                m,
                                                d[n + 8],
                                                20,
                                                1163531501
                                            )),
                                            (r = md5_gg(
                                                r,
                                                (i = md5_gg(
                                                    i,
                                                    (m = md5_gg(m, f, r, i, d[n + 13], 5, -1444681467)),
                                                    f,
                                                    r,
                                                    d[n + 2],
                                                    9,
                                                    -51403784
                                                )),
                                                m,
                                                f,
                                                d[n + 7],
                                                14,
                                                1735328473
                                            )),
                                            i,
                                            m,
                                            d[n + 12],
                                            20,
                                            -1926607734
                                        )),
                                        (r = md5_hh(
                                            r,
                                            (i = md5_hh(
                                                i,
                                                (m = md5_hh(m, f, r, i, d[n + 5], 4, -378558)),
                                                f,
                                                r,
                                                d[n + 8],
                                                11,
                                                -2022574463
                                            )),
                                            m,
                                            f,
                                            d[n + 11],
                                            16,
                                            1839030562
                                        )),
                                        i,
                                        m,
                                        d[n + 14],
                                        23,
                                        -35309556
                                    )),
                                    (r = md5_hh(
                                        r,
                                        (i = md5_hh(
                                            i,
                                            (m = md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)),
                                            f,
                                            r,
                                            d[n + 4],
                                            11,
                                            1272893353
                                        )),
                                        m,
                                        f,
                                        d[n + 7],
                                        16,
                                        -155497632
                                    )),
                                    i,
                                    m,
                                    d[n + 10],
                                    23,
                                    -1094730640
                                )),
                                (r = md5_hh(
                                    r,
                                    (i = md5_hh(
                                        i,
                                        (m = md5_hh(m, f, r, i, d[n + 13], 4, 681279174)),
                                        f,
                                        r,
                                        d[n + 0],
                                        11,
                                        -358537222
                                    )),
                                    m,
                                    f,
                                    d[n + 3],
                                    16,
                                    -722521979
                                )),
                                i,
                                m,
                                d[n + 6],
                                23,
                                76029189
                            )),
                            (r = md5_hh(
                                r,
                                (i = md5_hh(
                                    i,
                                    (m = md5_hh(m, f, r, i, d[n + 9], 4, -640364487)),
                                    f,
                                    r,
                                    d[n + 12],
                                    11,
                                    -421815835
                                )),
                                m,
                                f,
                                d[n + 15],
                                16,
                                530742520
                            )),
                            i,
                            m,
                            d[n + 2],
                            23,
                            -995338651
                        )),
                        (r = md5_ii(
                            r,
                            (i = md5_ii(
                                i,
                                (m = md5_ii(m, f, r, i, d[n + 0], 6, -198630844)),
                                f,
                                r,
                                d[n + 7],
                                10,
                                1126891415
                            )),
                            m,
                            f,
                            d[n + 14],
                            15,
                            -1416354905
                        )),
                        i,
                        m,
                        d[n + 5],
                        21,
                        -57434055
                    )),
                    (r = md5_ii(
                        r,
                        (i = md5_ii(
                            i,
                            (m = md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)),
                            f,
                            r,
                            d[n + 3],
                            10,
                            -1894986606
                        )),
                        m,
                        f,
                        d[n + 10],
                        15,
                        -1051523
                    )),
                    i,
                    m,
                    d[n + 1],
                    21,
                    -2054922799
                )),
                (r = md5_ii(
                    r,
                    (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)), f, r, d[n + 15], 10, -30611744)),
                    m,
                    f,
                    d[n + 6],
                    15,
                    -1560198380
                )),
                i,
                m,
                d[n + 13],
                21,
                1309151649
            )),
            (r = md5_ii(
                r,
                (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 4], 6, -145523070)), f, r, d[n + 11], 10, -1120210379)),
                m,
                f,
                d[n + 2],
                15,
                718787259
            )),
            i,
            m,
            d[n + 9],
            21,
            -343485551
        )),
            (m = safe_add(m, h)),
            (f = safe_add(f, t)),
            (r = safe_add(r, g)),
            (i = safe_add(i, e));
    }
    return Array(m, f, r, i);
}
function md5_cmn(d, _, m, f, r, i) {
    return safe_add(bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r), m);
}
function md5_ff(d, _, m, f, r, i, n) {
    return md5_cmn((_ & m) | (~_ & f), d, _, r, i, n);
}
function md5_gg(d, _, m, f, r, i, n) {
    return md5_cmn((_ & f) | (m & ~f), d, _, r, i, n);
}
function md5_hh(d, _, m, f, r, i, n) {
    return md5_cmn(_ ^ m ^ f, d, _, r, i, n);
}
function md5_ii(d, _, m, f, r, i, n) {
    return md5_cmn(m ^ (_ | ~f), d, _, r, i, n);
}
function safe_add(d, _) {
    var m = (65535 & d) + (65535 & _);
    return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m);
}
function bit_rol(d, _) {
    return (d << _) | (d >>> (32 - _));
}

export const _checkExistFilter = (filterCheck, x) => {
    const check = filterCheck.split(";");
    for (let i = 0; i < check.length; i++) {
        if (check[i].includes(x)) {
            check.splice(i, 1);
            break;
        }
    }
    return check.join(";");
};
