import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Table, Popconfirm, notification } from "antd";

import {
    EditOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    CaretRightOutlined,
    CloudUploadOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";

import api from "@api";
import qs from 'qs'

import './index.scss';
import { userCache } from "../../utils/user";

let { Search } = Input;

let searchText = ''

const Video = (props) => {

    const { history, location: { search } } = props;
    const queries = qs.parse(search.substr(1))

    const [scrollY, setScrollY] = useState(window.innerHeight - 265)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
        showSizeChanger: false,
    })
    const [sorter, setSorter] = useState({
        field: null,
        order: null,
    })
    const [playlists, setPlaylists] = useState([]);
    const [users, setUsers] = useState([]);

    const togglePublic = (row) => {
        api.put(`/video/${row.id}`, {
            is_public: !row.is_public
        })
            .then(({ status }) => {
                if (status) {
                    let newDataSource = dataSource.map(r => {
                        return r.id === row.id && (r.is_public = !r.is_public), r
                    })
                    setDataSource(newDataSource)
                }
            })
    }

    // const deleteRow = (row) => {
    //     api.delete(`/video/${row.id}`)
    //         .then(({ status }) => {
    //             if (status) {
    //                 fetch()
    //             }
    //         })
    // }

    const cleanSourceVideo = (row) => {
        api.put(`/video/clean_source/${row.id}`)
            .then(({ status, message }) => {
                if (status) {
                    let newDataSource = dataSource.map(r => {
                        return r.id === row.id && (r.source = ''), r
                    })
                    setDataSource(newDataSource)
                    notification.success({
                        message: 'Thông báo',
                        description: message,
                    });
                } else {
                    notification.warning({
                        message: 'Thông báo',
                        description: message,
                    });
                }
            })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Playlist',
            dataIndex: 'playlist_id',
            key: 'playlist_id',
            render(value) {
                return <span>{playlists.find(pl => pl.id == value)?.text}</span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
        },
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'User',
            dataIndex: 'user_id',
            key: 'user_id',
            render(value) {
                return <span>{users.find(usr => usr.id == value)?.text}</span>
            }
        },
        {
            title: 'Action',
            align: 'center',
            width: 160,
            render(value, row) {
                return (
                    <>
                        <Button title="View" type="text" size={"small"} onClick={() => {
                            api.get(`/video/iframe/${row.key}`)
                                .then(({ status, url }) => {
                                    if (status) {
                                        window.open(url, '_blank')
                                    }
                                })
                        }}>
                            <CaretRightOutlined />
                        </Button>
                        <Button type="text" size={"small"} onClick={() => togglePublic(row)}>
                            {row.is_public ? <EyeOutlined title="Public" /> : <EyeInvisibleOutlined title="Private" />}
                        </Button>
                        <Button title="Update" type="text" size={"small"} onClick={() => {
                            history.push(`/video/${row.id}`)
                        }}>
                            <EditOutlined />
                        </Button>
                        {
                            userCache.instance.hasRole('ADMIN') && row.source ? (
                                <Popconfirm title="Bạn có chắn muốn xóa không?" onConfirm={() => cleanSourceVideo(row)} okText='Có' cancelText='Không'>
                                    <Button title="Clean source video" type="text" size={"small"}>
                                        <CloseCircleOutlined />
                                    </Button>
                                </Popconfirm>
                            ) : null
                        }
                        {/* {
                            userCache.instance.getId() == row.user_id ? (
                                <Popconfirm title="Bạn có chắn muốn xóa không?" onConfirm={() => deleteRow(row)} okText='Có' cancelText='Không'>
                                    <Button type="text" size={"small"}>
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            ) : null
                        } */}
                    </>
                )
            },
            key: 'action',
        },
    ];

    const fetch = async () => {
        setLoading(true)
        let filters = []
        if (searchText) {
            filters.push(`slug:${searchText}`)
        }
        if (queries.playlist_id) {
            filters.push(`playlist_id:${queries.playlist_id}`)
        }
        let params = {
            _page: pagination.current,
            _perPage: pagination.pageSize,
        }
        if (filters.length) {
            params._filter = filters.join(';')
        }
        if (sorter.field && sorter.order) {
            params._orderBy = `${sorter.field}:${sorter.order}`
        }
        api.get('/video', {
            params
        })
            .then(({ status, data, meta: { pagination: { current_page, per_page, total } } }) => {
                setLoading(false)
                if (status) {
                    setDataSource(data)
                    setPagination({
                        ...pagination,
                        current: current_page,
                        pageSize: per_page,
                        total: total,
                    })
                }
            })
    };

    useEffect(() => {
        api.get('/playlist/options')
            .then(({ status, data }) => {
                status && setPlaylists(data)
            })
        api.get('/user/options')
            .then(({ status, data }) => {
                status && setUsers(data)
            })
        window.addEventListener('resize', () => {
            setScrollY(window.innerHeight - 265)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [pagination.current, sorter, search])

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Search
                        placeholder="Tìm kiếm video" style={{ width: 250 }}
                        onSearch={(value) => {
                            if (searchText != value) {
                                searchText = value
                                fetch()
                            }
                        }}
                    />
                    <div>
                        {queries.playlist_id ? (
                            <Button onClick={() => history.push(`/video/upload?playlist_id=${queries.playlist_id}`)} type="primary" icon={<CloudUploadOutlined />}>
                                Upload
                            </Button>
                        ) : (
                            <Button onClick={() => history.push("/video/upload")} type="primary" icon={<CloudUploadOutlined />}>
                                Upload
                            </Button>

                        )}
                    </div>
                </div>
                <Table
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={'id'}
                    pagination={pagination}
                    onChange={({ current }, filters, { field, order }, { action }) => {
                        if (action === 'sort') {
                            setSorter({ ...sorter, field, order: order === 'ascend' ? 'asc' : 'desc' })
                        }
                        if (action === 'paginate') {
                            setPagination({ ...pagination, current })
                        }
                    }}
                    scroll={{ y: scrollY }}
                />
            </Col>
        </Row>
    );
};

export default Video;
