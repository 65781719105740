import React, { useEffect, useState } from "react";
import {
    Button,
    Input,
    Row,
    Col,
    Tabs,
    Select,
    Upload,
    Progress,
} from "antd";

import {
    UploadOutlined
} from "@ant-design/icons";

import api from '@api'
import Config from "@config";

import CenterArea from "@components/CenterArea";
import NewForm, { FieldFloatItem } from "@components/NewForm";

const { TabPane } = Tabs;

const Detail = (props) => {
    const { history } = props;
    const [form] = NewForm.useForm();
    const { id } = props.match.params;

    const [playlists, setPlaylists] = useState([]);
    const [videoSource, setVideoSource] = useState('');
    const [uploadLength, setUploadLength] = useState(0);

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            if (id) {
                let data = {
                    is_public: values.is_public || false,
                    name: values.name,
                    playlist_id: values.playlist_id,
                }
                api.put(`/video/${id}`, data)
                    .then(({ status }) => {
                        if (status) {
                            handleBack()
                        }
                    })
            }
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/video`,
        });
    };

    const formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) {
            bytes = Math.round(bytes / 1073741824 * 100) / 100 + 'GB';
        } else if (bytes >= 1048576) {
            bytes = Math.round(bytes / 1048576 * 100) / 100 + 'MB';
        } else if (bytes >= 1024) {
            bytes = Math.round(bytes / 1024 * 100) / 100 + 'KB';
        } else if (bytes > 1) {
            bytes = bytes + 'bytes';
        } else if (bytes == 1) {
            bytes = bytes + 'byte';
        } else {
            bytes = '0byte';
        }

        return bytes;
    }

    useEffect(() => {
        api.get('/playlist/options')
            .then(({ status, data }) => {
                status && setPlaylists(data)
            })
        if (id) {
            api.get(`/video/${id}`)
                .then(({ status, data }) => {
                    if (status) {
                        form.setFieldsValue({
                            name: data.name,
                            key: data.key,
                            source: data.source,
                            is_public: data.is_public,
                            playlist_id: data.playlist_id?.toString(),
                        })
                        setVideoSource(data.source)
                    }
                })
        }
    }, [])

    return (
        <Tabs defaultActiveKey={1}>
            <TabPane tab="Thông tin video" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm id="course-editor-form" className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Key"
                                    name="key"
                                >
                                    <Input readOnly />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Tên video"
                                    name="name"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Public"
                                    name="is_public"
                                >
                                    <Select>
                                        <Select.Option value={true}>Công khai</Select.Option>
                                        <Select.Option value={false}>Riêng tư</Select.Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Playlist"
                                    name="playlist_id"
                                >
                                    <Select>
                                        {playlists.map((playlist, idx) => (
                                            <Select.Option key={idx} value={playlist.id.toString()}>{playlist.text}</Select.Option>
                                        ))}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Source"
                                    name="source"
                                >
                                    <Input readOnly />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                {
                                    !videoSource ? (
                                        <Upload
                                            action={`${Config.BASE_URL}/video/${id}/upload`}
                                            headers={{
                                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                            }}
                                            name="file"
                                            onChange={({ fileList: { length } }) => {
                                                setUploadLength(length)
                                            }}
                                            itemRender={(e, { response, percent, name, size }) => {
                                                if (response && response.status) {
                                                    let { source, key, name } = response.data
                                                    setTimeout(() => {
                                                        form.setFieldsValue({ source, key, name })
                                                    })
                                                }
                                                return (
                                                    <div>
                                                        <Progress size="small" percent={parseInt(response ? percent : Math.min(99, percent))} />
                                                        <span>{name}</span>
                                                        <span> </span>
                                                        <span>({formatSizeUnits(size)})</span>
                                                    </div>
                                                )
                                            }}
                                        >
                                            {uploadLength == 0 ? <Button icon={<UploadOutlined />}>Click to Upload</Button> : null}
                                        </Upload>
                                    ) : null
                                }
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <FieldFloatItem>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                            >
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default Detail;
