import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Table, Tag } from "antd";

import {
    EditOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import api from "@api";

let { Search } = Input;

let searchText = ''

const User = (props) => {

    const { history } = props;

    const [scrollY, setScrollY] = useState(window.innerHeight - 265)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
        showSizeChanger: false,
    })

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render(value, row) {
                return row.active ? 'Kích hoạt' : 'Khóa'
            },
        },
        {
            title: 'Role',
            dataIndex: 'roles',
            key: 'roles',
            render(value) {
                return value.map ? value.map((role, idx) => <Tag key={idx}>{role}</Tag>) : ''
            },
        },
        {
            title: 'Action',
            align: 'center',
            render(text, row) {
                return (
                    <>
                        <Button type="text" size={"small"} onClick={() => {
                            history.push(`/user/${row.id}`)
                        }}>
                            <EditOutlined />
                        </Button>
                    </>
                )
            },
            key: 'action',
        },
    ];

    const fetch = async () => {
        setLoading(true)
        let filters = []
        if (searchText) {
            filters.push(`slug:${searchText}`)
        }
        let params = {
            _page: pagination.current,
            _perPage: pagination.pageSize,
        }
        if (filters.length) {
            params._filter = filters.join(';')
        }
        api.get('/user', {
            params
        })
            .then(({ status, data, meta: { pagination: { current_page, per_page, total } } }) => {
                setLoading(false)
                if (status) {
                    setDataSource(data)
                    setPagination({
                        ...pagination,
                        current: current_page,
                        pageSize: per_page,
                        total: total,
                    })
                }
            })
    };

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScrollY(window.innerHeight - 265)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [pagination.current])

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Search
                        placeholder="Tìm kiếm user" style={{ width: 250 }}
                        onSearch={(value) => {
                            if (searchText != value) {
                                searchText = value
                                fetch()
                            }
                        }}
                    />
                    <div>
                        <Button onClick={() => history.push("/user/new")} type="primary" icon={<PlusOutlined />}>
                            Thêm mới
                        </Button>
                    </div>
                </div>
                <Table
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={'id'}
                    pagination={pagination}
                    onChange={({ current }) => {
                        setPagination({ ...pagination, current })
                    }}
                    scroll={{ y: scrollY }}
                />
            </Col>
        </Row>
    );
};

export default User;
