import React from "react";
import { Layout, Menu, Dropdown, Button, Row, Col } from "antd";

import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { saveBranch } from "@actions/branch";
import { removeAcl } from "@actions/acl";
import { getUserInfo } from "@utils/auth";

function Header(props) {

    const { history } = props
    const { Header } = Layout;

    const dispatch = useDispatch();
    let user = getUserInfo();

    const redirectTo = (route) => {
        history.replace(route);
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <a onClick={() => redirectTo("/profile")}>Profile</a>
            </Menu.Item>
            <Menu.Item onClick={() => logOut()}>Đăng xuất</Menu.Item>
        </Menu>
    );

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("me");
        localStorage.removeItem("roles");
        localStorage.removeItem("acl");
        dispatch(saveBranch({ branch_id: null }));
        dispatch(removeAcl());
        location.href = '/login';
    };

    return (
        <Header className="noPrint  layout-header">
            <Row className="container-header">
                <Col>
                </Col>
                <Col style={{ padding: "0 24px" }}>
                    Xin chào, {user.username || user.name}
                    <Dropdown overlay={menu}>
                        <Button icon={<UserOutlined />} shape="circle-outline" className="icon-user" />
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    );
}

export default Header;
