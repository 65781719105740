import React from "react";
import {
    HomeOutlined,
    UserOutlined,
    VideoCameraOutlined,
    AppstoreOutlined,
} from "@ant-design/icons";

const routes = [
    {
        key: 'home',
        name: "Trang chủ",
        icon: <HomeOutlined />,
        url: "/",
    },
    {
        key: 'user',
        name: "User",
        icon: <UserOutlined />,
        url: "/user",
        roles: 'ADMIN',
    },
    {
        key: 'playlist',
        name: "Playlist",
        icon: <AppstoreOutlined />,
        url: "/playlist",
        roles: 'EMPLOYEE',
    },
    {
        key: 'video',
        name: "Video",
        icon: <VideoCameraOutlined />,
        url: "/video",
        roles: 'EMPLOYEE',
    },
    {
        key: 'hello',
        name: "Hello",
        icon: <UserOutlined />,
        roles: 'ADMIN',
        children: [
            {
                key: 'hello1',
                name: "Hello 1",
                url: "/hello1",
            },
            {
                key: 'hello2',
                name: "Hello 2",
                url: "/hello2",
            },
            {
                key: 'hello3',
                name: "Hello 3",
                url: "/hello3",
            },
        ],
    },
    {
        key: 'world',
        name: "Wolrd",
        icon: <UserOutlined />,
        roles: 'ADMIN',
        children: [
            {
                key: 'world1',
                name: "Wolrd 1",
                url: "/world1",
            },
            {
                key: 'world2',
                name: "Wolrd 2",
                url: "/world2",
            },
            {
                key: 'world3',
                name: "Wolrd 3",
                url: "/world3",
            },
        ],
    },
];

export default routes
