import React, { useEffect, useState } from "react";
import {
    Button,
    Input,
    Row,
    Col,
    Tabs,
} from "antd";

import api from '@api'

import CenterArea from "@components/CenterArea";
import NewForm, { FieldFloatItem } from "@components/NewForm";

const { TabPane } = Tabs;

const Detail = (props) => {
    const { history } = props;
    const [form] = NewForm.useForm();
    const { id } = props.match.params;

    const [loading, setLoading] = useState(false)

    const onFinish = () => {
        setLoading(true)
        form.validateFields().then(async (values) => {
            if (id) {
                api.put(`/playlist/${id}`, values)
                    .then(({ status }) => {
                        setLoading(false)
                        if (status) {
                            handleBack()
                        }
                    })
            } else {
                api.post('/playlist', values)
                    .then(({ status }) => {
                        setLoading(false)
                        if (status) {
                            handleBack()
                        }
                    })
            }
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/playlist`,
        });
    };

    useEffect(() => {
        if (id) {
            let params = {}
            api.get(`/playlist/${id}`, {
                params
            })
                .then(({ status, data }) => {
                    if (status) {
                        form.setFieldsValue({
                            name: data.name,
                            username: data.username,
                            active: data.active,
                            roles: data.roles,
                        })
                    }
                })
        } else {
            form.setFieldsValue({
                active: true,
            })
        }
    }, [])

    return (
        <Tabs defaultActiveKey={1}>
            <TabPane tab="Thông tin playlist" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm id="course-editor-form" className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Name"
                                    name="name"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <FieldFloatItem>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }} loading={loading}>
                                                Quay lại
                                            </Button>
                                            <Button
                                                loading={loading}
                                                htmlType="submit"
                                                type="primary"
                                            >
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default Detail;
