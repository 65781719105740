export function findActiveMenu(pathname) {
    const items = [
        ['home', '/'],
        ['user', ['/user', '/user/new', '/user/\\d{1,}']],
        ['playlist', ['/playlist', '/playlist/new', '/playlist/\\d{1,}']],
        ['video', ['/video', '/video/upload', '/video/\\d{1,}']],
        ['hello', null, [
            ['hello1', '/hello1'],
            ['hello2', '/hello2'],
            ['hello3', '/hello3'],
        ]],
        ['world', null, [
            ['world1', '/world1'],
            ['world2', '/world2'],
            ['world3', '/world3'],
        ]],
    ]
    return search(pathname, items)
}

function check(pathname, reg) {
    return new RegExp(`^${reg}$`).test(pathname)
}

function search(pathname, items, parent) {
    for (let i = 0; i < items.length; i++) {
        let item = items[i]
        if (item[1] !== null) {
            if (item[1] instanceof Array) {
                for (let reg of item[1]) {
                    if (check(pathname, reg)) {
                        return {
                            active: item[0],
                            open: parent,
                        }
                    }
                }
            } else {
                if (check(pathname, item[1])) {
                    return {
                        active: item[0],
                        open: parent,
                    }
                }
            }
        } else {
            let nested = search(pathname, item[2], item[0])
            if (nested) {
                return nested
            }
        }
    }
}
