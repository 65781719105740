import React, { useEffect, useState } from "react";
import {
    Progress, Select,
} from "antd";

import CenterArea from "@components/CenterArea";

import {
    InboxOutlined
} from "@ant-design/icons";

import api from "@api";
import Config from "@config";
import Dragger from "antd/lib/upload/Dragger";
import qs from 'qs'

const UploadPage = (props) => {

    const { location: { search } } = props;
    const queries = qs.parse(search.substr(1));
    const [playlists, setPlaylists] = useState([]);
    const [playlistId, setPlaylistId] = useState(queries.playlist_id);

    const uploadProps = {
        name: 'file',
        multiple: true,
        action: `${Config.BASE_URL}/upload_video`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data() {
            let data = {}
            if (playlistId) {
                data.playlist_id = playlistId
            }
            return data
        },
    }

    const formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) {
            bytes = Math.round(bytes / 1073741824 * 100) / 100 + 'GB';
        } else if (bytes >= 1048576) {
            bytes = Math.round(bytes / 1048576 * 100) / 100 + 'MB';
        } else if (bytes >= 1024) {
            bytes = Math.round(bytes / 1024 * 100) / 100 + 'KB';
        } else if (bytes > 1) {
            bytes = bytes + 'bytes';
        } else if (bytes == 1) {
            bytes = bytes + 'byte';
        } else {
            bytes = '0byte';
        }

        return bytes;
    }

    useEffect(() => {
        api.get('/playlist/options')
            .then(({ status, data }) => {
                status && setPlaylists(data)
            })
    }, [])

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <span>Playlist: </span>
            <Select
                allowClear
                placeholder="Chọn playlist"
                style={{ marginBottom: 10, minWidth: 200 }}
                value={playlistId}
                onChange={setPlaylistId}
            >
                {playlists.map((playlist, idx) => (
                    <Select.Option key={idx} value={playlist.id.toString()}>{playlist.text}</Select.Option>
                ))}
            </Select>
            <Dragger
                className="upload_videos"
                {...uploadProps}
                itemRender={(e, file) => {
                    return (
                        <div style={{ border: '1px solid #333', padding: 10, marginTop: 10 }}>
                            <Progress percent={parseInt(file.response ? file.percent : Math.min(99, file.percent))} />
                            <div>
                                <span>{file.name}</span>
                                <span> </span>
                                <span>({formatSizeUnits(file.size)})</span>
                            </div>
                        </div>
                    )
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p>
            </Dragger>
        </CenterArea>
    );
};

export default UploadPage;
