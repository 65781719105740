import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import { getCurrentVersion, showNewVersionPopup } from "@utils";
import Config from "@config";
import api from "@api";
import { mainRoutes } from "@routes";
import "./index.scss";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Forbidden from "./Forbidden";

import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { save_roles, save_user_data } from "../../actions/auth";
import User, { setCacheUser } from "../../utils/user";

const { Content } = Layout;

const Main = (props) => {

    const { history } = props
    const [user, setUser] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        let token = localStorage.getItem('token')
        if (token) {
            api.get("/user/me").then(({ status, token, data }) => {
                if (status) {
                    let roles = jwtDecode(token)
                    dispatch(save_roles(roles))
                    dispatch(save_user_data(data))
                    let newUser = new User(roles, data)
                    setCacheUser(newUser)
                    setUser(newUser)
                }
            });
        } else {
            history.replace('/login')
        }

        if (Config.NODE_ENV == "production") {
            getCurrentVersion().then((hash) => {
                localStorage.setItem("hash_version", hash);
                const checkVersionItv = setInterval(() => {
                    getCurrentVersion().then((hash) => {
                        if (localStorage.getItem("hash_version") != hash) {
                            clearInterval(checkVersionItv);
                            showNewVersionPopup();
                        }
                    });
                }, 60000);
            });
        }
    }, []);

    return (
        <>
            {user ? (
                <Layout className="main-container">
                    <Sidebar user={user} />
                    <Layout className="site-layout">
                        <Header history={history} />
                        <Content id="main-content" className="site-layout-background">
                            <Switch>
                                {mainRoutes.map((item, index) => {
                                    if (user.hasRole(item.roles)) {
                                        return (
                                            <Route key={index} path={item.path} exact={true} component={item.component} />
                                        )
                                    } else {
                                        return (
                                            <Route key={index} path={item.path} component={Forbidden} />
                                        )
                                    }
                                })}
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            ) : null}
        </>
    );
};

export default Main;
