export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const SAVE_ROLES = "SAVE_ROLES";
export const SAVE_USER_DATA = "SAVE_USER_DATA";

export function login_pending() {
    return {
        type: LOGIN_PENDING,
        payload: {},
    };
}

export function login_success(data) {
    return {
        type: LOGIN_SUCCESS,
        payload: data,
    };
}

export function login_error(message) {
    return {
        type: LOGIN_ERROR,
        payload: {
            response: message,
        },
    };
}

export function logout() {
    return {
        type: LOGOUT,
    };
}

export function save_roles(roles) {
    return {
        type: SAVE_ROLES,
        payload: {
            roles,
        },
    };
}

export function save_user_data(data) {
    return {
        type: SAVE_USER_DATA,
        payload: {
            data,
        },
    };
}
