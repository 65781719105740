import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Table, Popconfirm } from "antd";

import {
    EditOutlined,
    VideoCameraOutlined,
    CloudUploadOutlined,
    PlusOutlined,
    DeleteOutlined,
} from "@ant-design/icons";

import api from "@api";
import { userCache } from "../../utils/user";

let { Search } = Input;

let searchText = ''

const Playlist = (props) => {

    const { history } = props;

    const [scrollY, setScrollY] = useState(window.innerHeight - 265)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
        showSizeChanger: false,
    })
    const [users, setUsers] = useState([]);

    const deleteRow = (row) => {
        api.delete(`/playlist/${row.id}`)
            .then(({ status }) => {
                if (status) {
                    fetch()
                }
            })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Number of videos',
            dataIndex: 'number_of_videos',
            key: 'number_of_videos',
        },
        {
            title: 'User',
            dataIndex: 'user_id',
            key: 'user_id',
            render(value) {
                return <span>{users.find(usr => usr.id == value)?.text}</span>
            }
        },
        {
            title: 'Action',
            align: 'center',
            render(text, row) {
                return (
                    <>
                        <Button title="Video list" type="text" size={"small"} onClick={() => {
                            history.push(`/video?playlist_id=${row.id}`)
                        }}>
                            <VideoCameraOutlined />
                        </Button>
                        <Button title="Upload video" type="text" size={"small"} onClick={() => {
                            history.push(`/video/upload?playlist_id=${row.id}`)
                        }}>
                            <CloudUploadOutlined />
                        </Button>
                        <Button title="Update" type="text" size={"small"} onClick={() => {
                            history.push(`/playlist/${row.id}`)
                        }}>
                            <EditOutlined />
                        </Button>
                        {
                            userCache.instance.getId() == row.user_id && row.number_of_videos == 0 ? (
                                <Popconfirm title="Bạn có chắn muốn xóa không?" onConfirm={() => deleteRow(row)} okText='Có' cancelText='Không'>
                                    <Button title="Delete" type="text" size={"small"}>
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            ) : null
                        }
                    </>
                )
            },
            key: 'action',
        },
    ];

    const fetch = async () => {
        setLoading(true)
        let filters = []
        if (searchText) {
            filters.push(`slug:${searchText}`)
        }
        let params = {
            _page: pagination.current,
            _perPage: pagination.pageSize,
        }
        if (filters.length) {
            params._filter = filters.join(';')
        }
        api.get('/playlist', {
            params
        })
            .then(({ status, data, meta: { pagination: { current_page, per_page, total } } }) => {
                setLoading(false)
                if (status) {
                    setDataSource(data)
                    setPagination({
                        ...pagination,
                        current: current_page,
                        pageSize: per_page,
                        total: total,
                    })
                }
            })
    };

    useEffect(() => {
        api.get('/user/options')
            .then(({ status, data }) => {
                status && setUsers(data)
            })
        window.addEventListener('resize', () => {
            setScrollY(window.innerHeight - 265)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [pagination.current])

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Search
                        placeholder="Tìm kiếm user" style={{ width: 250 }}
                        onSearch={(value) => {
                            if (searchText != value) {
                                searchText = value
                                fetch()
                            }
                        }}
                    />
                    <div>
                        <Button onClick={() => history.push("/playlist/new")} type="primary" icon={<PlusOutlined />}>
                            Thêm mới
                        </Button>
                    </div>
                </div>
                <Table
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={'id'}
                    pagination={pagination}
                    onChange={({ current }) => {
                        setPagination({ ...pagination, current })
                    }}
                    scroll={{ y: scrollY }}
                />
            </Col>
        </Row>
    );
};

export default Playlist;
